import baseAPI from '@/api/base';
import { PLACES_DETAIL_URL, PLACES_SUGGESTION_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { PlaceDetail, PlacesSuggestionQuery } from '@/dtos/google_cloud/places';

class Places {
    static getSuggestions = async(placesSuggestionQuery: PlacesSuggestionQuery) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(PLACES_SUGGESTION_URL, {
                    params: placesSuggestionQuery
                })

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getDetail = async(placeDetail: PlaceDetail) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(`${PLACES_DETAIL_URL}/${placeDetail.placeId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Places