// waitlist
export const SET_WAITLIST_REGISTRATION = 'registerWaitlist'

// account
export const SET_ACCOUNT = 'setAccount';
export const SET_SESSION_TOKEN = 'setToken';

// pre_approval
export const SET_PRE_APPROVAL_REGISTRATION = 'registerPreApproval'

// partnership
export const SET_CONTACT_US_FORM = 'contactUsForm'

// personal_information
export const SET_PERSONAL_INFORMATION_REGISTRATION = 'registerPersonalInformation'

// declarations
export const SET_DECLARATION_REGISTRATION = 'registerDeclaration'

// declarations
export const SET_EMPLOYMENT_REGISTRATION = 'registerEmployment'

// property
export const SET_PROPERTY_REGISTRATION = 'registerProperty'

// partnership
export const SET_CONTACT_FORM = 'contactForm'

// places
export const SET_PLACES_SUGGESTIONS = 'placesSuggestions'
export const SET_PLACE_DETAIL = 'placeDetail'

// error
export const SET_ERRORMSG = 'setErrorMsg';