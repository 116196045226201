import baseAPI from '@/api/base';
import { PRE_APPROVAL_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { PreApprovalBody } from '@/dtos/pre_approval';

class PreApproval {
    static post = async(preApprovalBody: PreApprovalBody) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(PRE_APPROVAL_URL, {
                    ...preApprovalBody
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default PreApproval