import store from '@/store';
import router from '..'
import { CLEAR_ERRORMSG } from '@/store/action_types';
import { RouteLocationNormalized } from 'vue-router';
import { resetLoaders } from '@/utils/loaders';

export const ifAuthDeny = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    // clear error messages
    store.dispatch(CLEAR_ERRORMSG)

    // clear isLoading states
    resetLoaders()

    if (store.getters.sessionToken != null && store.getters.account.data != null) {
        router.push(from.fullPath)
        return
    }
    next();
}