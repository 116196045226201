import { AxiosResponse } from 'axios'
import { SET_CONTACT_US_FORM, SET_ERRORMSG } from '../../mutation_types'
import {
    CONTACT_US_FORM_REGISTRATION,
    CLEAR_CONTACT_US_FORM_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state'
import ContactUsForm from '@/services/contact_us/form'
import { Module } from 'vuex'
import { ContactUsFormBody } from '@/dtos/contact_us/form'

const contactUsFormRegistration: Module<any, {}> = {
    state: {
        contactUsFormRegistration: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        contactUsFormRegistration: (state: { contactUsFormRegistration: any }) => state.contactUsFormRegistration
    },
    mutations: {
        [SET_CONTACT_US_FORM]: (state: { contactUsFormRegistration: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.contactUsFormRegistration.data = payload.data : null, state.contactUsFormRegistration.isLoading = payload.isLoading },
    },
    actions: {
        [CONTACT_US_FORM_REGISTRATION]: async({ commit }: any, payload: any) => {
            commit(SET_CONTACT_US_FORM, { data: null, isLoading: true })

            let result: AxiosResponse = await ContactUsForm.post(payload as ContactUsFormBody)

            if(result) {
                if (result.data.status) {
                    commit(SET_CONTACT_US_FORM, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CONTACT_US_FORM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CONTACT_US_FORM, { isLoading: false })
            }
            return null
        },
        [CLEAR_CONTACT_US_FORM_ISLOADING]: ({ commit }) => {
            commit(SET_CONTACT_US_FORM, { isLoading: false })
        }
    }
}

export default contactUsFormRegistration