<template>
    <div>
        <navbar v-show="navbarIsVisible"/>
    
        <div class="content">
            <br>
            <br>
            <section class="hero-section">
                <div class="row align-items-center">
                    <!-- Text Area -->
                    <div class="col-lg-6 col-sm-12 scroll-animate" data-animate="animate__fadeInLeft">
                        <h1 class="title display-5 fw-bold"><span style="color: #052EBF;">Tap</span> into your home wealth instantly.</h1>
                        <div class="d-lg-none">
                            <br>
                            <br>
                            <img src="../assets/images/hero_img.svg" alt="Meeting with clients" class="col-lg-11 offset-lg-1 img-fluid rounded-3">
                            <br>
                        </div>
                        <br>
                        <h4 class="my-4 sub-title">
                            <div class="d-flex">
                                <div class="flex-shrink-0" style="margin-right: 20px">
                                    <img src="../assets/images/🦆 icon _check circle_.png" alt="" srcset="">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span class="text">Unlock up to $2,000,000 of your home equity within 3 weeks with Equihome.</span>
                                </div>
                            </div>
                        </h4>
                        <h4 class="mb-4 sub-title">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0" style="margin-right: 20px">
                                    <img src="../assets/images/🦆 icon _check circle_.png" alt="Check Circle Icon" srcset="">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span class="text">No monthly payments for up to 15 years.</span>
                                </div>
                            </div>
                        </h4>
                        <br>
                        
                        <div class="d-none d-lg-block">
                            <br>
                        </div>
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="row">
                                    <div class="col-6">
                                        <router-link :to="{ name: WAITLIST_REGISTRATION }" class="col-12 btn btn-get-started me-2">Join Waitlist</router-link>
                                    </div>
                                    <div class="col-6">
                                        <router-link :to="{ name: LEARN_MORE_ROUTE }" class="col-12 btn btn-learn-more me-2">Learn More</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Image Area -->
                    <div class="col-lg-6 col-sm-12 d-none d-lg-block scroll-animate" data-animate="animate__fadeInRight">
                        <div class="d-lg-none">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                        </div>
                        <img src="../assets/images/hero_img.svg" alt="Meeting with clients" class="col-lg-11 offset-lg-1 img-fluid rounded-3">
                        <img src="../assets/images/process-vector.png" style="display: none" alt="Meeting with clients" class="col-lg-11 offset-lg-1 img-fluid rounded-3">
                        
                    </div>
    
                </div>
            </section>
            <br>
            <br>
            <br>
            <div class="d-none d-lg-block">
                <br>
                <br>
            </div>

            <!-- <div class="sticky_parent">
                <div class="sticky">
                    <div class="scroll_section">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="card description-card scroll-animate">
                                    <img src="../assets/images/process-1.png" class="col-lg-11 col-sm-12" alt="" srcset="">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex align-items-center scroll-animate">
                                <div class="w-100">
                                    <h2 class="title"><strong>01</strong> <span style="font-weight: 400;">Pre-Approval</span></h2>
                                    <br>
                                    <p class="text">Answer simple questions about your property. Our AI-powered underwriting system swiftly evaluates your eligibility. In just a few minutes, receive your pre-approval decision and take the first step toward unlocking your home equity.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="card description-card scroll-animate">
                                    <img src="../assets/images/process-2.2.png" class="col-lg-11 col-sm-12" alt="" srcset="">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex align-items-center scroll-animate">
                                <div class="w-100">
                                    <h2 class="title"><strong>02</strong> <span style="font-weight: 400;">Onboarding</span></h2>
                                    <br>
                                    <p class="text">Upload basic documents and chat with your dedicated mortgage advisor to discuss your options further if you’d like. Our team ensures you have all the information you need to make an informed decision about accessing your home equity. We're here to guide you every step of the way.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="card description-card scroll-animate">
                                    <img src="../assets/images/process-3.png" class="col-lg-11 col-sm-12" alt="" srcset="">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex align-items-center scroll-animate">
                                <div class="w-100">
                                    <h2 class="title"><strong>03</strong> <span style="font-weight: 400;">Unlock</span></h2>
                                    <br>
                                    <p class="text">Explore various options of offer - including how much you want to access. Select the one that suits your needs, and unlock your equity. Once official agreements are signed, you can expect the cash in your account in less than a week. Our streamlined process ensures quick access to the funds you need.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <section class="processes d-none d-lg-block">
                <div class="row">
                    <div class="col-lg-12"> -->
                        <!-- <div class="card main-card" style="background-image: url('http://localhost:8081/img/process-vector.d9811201.png');  background-size: 50% auto; background-repeat: no-repeat; background-position: 100% center;">
                            <div class="card-body"> -->
                                <!-- <div class="card main-content" style="background-color: transparent; border-color: transparent">
                                    <div class="card-body" style="background-color: transparent; border-color: transparent"> -->
                                        <!-- <swiper class="swiper" :modules="modules" :onSwiper="setSwiper" :pagination="{ clickable: true }">
                                            <swiper-slide class="slide"> -->
                                        <div :class="[scrollableIsVisible ? 'visible_sticky_section' : 'invisible_sticky_section', 'sticky_parent d-none d-lg-block']" ref="targetDiv" style="background-image: url('https://www.equihome.com.au/img/process-vector.d9811201.png');  background-size: 50% auto; background-repeat: no-repeat; background-position: 100% center; background-attachment: fixed;">
                                            <div class="sticky" ref="targetDiv">
                                                <br>
                                                <br>
                                                <h1 class="text-center card-title scroll-animate" data-animate="animate__fadeInDown">Get Funded in as Little as 21 Days.</h1>
                                                <div class="scroll_section">
                                                    <div class="row" style="">
                                                        <div class="col-lg-4 offset-lg-2 col-sm-10 offset-sm-1">
                                                            <!-- <div class="card description-card scroll-animate"> -->
                                                                <img src="../assets/images/process-1.png" class="col-lg-12 col-sm-12" alt="" srcset="">
                                                            <!-- </div> -->
                                                        </div>
                                                        <div class="col-lg-5 offset-lg-1 col-sm-10 offset-sm-1 d-flex align-items-center scroll-animate">
                                                            <div class="w-100">
                                                                <h2 class="title"><strong>01</strong> <span style="font-weight: 400;">Pre-Approval</span></h2>
                                                                <br>
                                                                <p class="text">Answer simple questions about your property. Our AI-powered underwriting system swiftly evaluates your eligibility. In just a few minutes, receive your pre-approval decision and take the first step toward unlocking your home equity.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-4 offset-lg-2 col-sm-10 offset-sm-1">
                                                            <!-- <div class="card description-card scroll-animate"> -->
                                                                <img src="../assets/images/process-2.2.png" class="col-lg-12 col-sm-12" alt="" srcset="">
                                                            <!-- </div> -->
                                                        </div>
                                                        <div class="col-lg-5 offset-lg-1 col-sm-10 offset-sm-1 d-flex align-items-center scroll-animate">
                                                            <div class="w-100">
                                                                <h2 class="title"><strong>02</strong> <span style="font-weight: 400;">Onboarding</span></h2>
                                                                <br>
                                                                <p class="text">Upload basic documents and chat with your dedicated mortgage advisor to discuss your options further if you’d like. Our team ensures you have all the information you need to make an informed decision about accessing your home equity. We're here to guide you every step of the way.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-4 offset-lg-2 col-sm-10 offset-sm-1">
                                                            <!-- <div class="card description-card scroll-animate"> -->
                                                                <img src="../assets/images/process-3.png" class="col-lg-12 col-sm-12" alt="" srcset="">
                                                            <!-- </div> -->
                                                        </div>
                                                        <div class="col-lg-5 offset-lg-1 col-sm-10 offset-sm-1 d-flex align-items-center scroll-animate">
                                                            <div class="w-100">
                                                                <h2 class="title"><strong>03</strong> <span style="font-weight: 400;">Unlock</span></h2>
                                                                <br>
                                                                <p class="text">Explore various options of offer - including how much you want to access. Select the one that suits your needs, and unlock your equity. Once official agreements are signed, you can expect the cash in your account in less than a week. Our streamlined process ensures quick access to the funds you need.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                           
                                        <!-- </swiper> -->
                                    <!-- </div>
                                </div> -->
                            <!-- </div>
                        </div> -->
                    <!-- </div>
                </div> -->
                <!-- <div class="swiper-button-next" v-if="isVisible && !swiperInstance.isEnd" @click="nextSlide()"><i class="pi pi-chevron-right" style="color: white"></i></div>
                <div class="swiper-button-prev" v-if="isVisible && !swiperInstance.isBeginning" @click="previousSlide()"><i class="pi pi-chevron-left" style="color: white"></i></div> -->
            <!-- </section> -->
    
            <section class="processes d-sm-block d-lg-none">
                <div class="row">
                    <div class="col-12">
                        <div class="" style="background-image: url('http://localhost:8081/img/process-vector.d9811201.png');  background-size: 50% auto; background-repeat: no-repeat; background-position: 100% center;">
                            <h1 class="text-center card-title scroll-animate" style="overflow-wrap: normal" data-animate="animate__fadeInDown">Get pre-approved in minutes with AI.</h1>
                            <br>
                            <div class="scroll-container">
                                <div class="scroll-content">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-5 col-sm-12 d-flex align-items-center scroll-animate" data-animate="animate__fadeInUp">
                                                    <div class="w-100">
                                                        <h2 class="title"><strong>01</strong> <span style="font-weight: 400;">Pre-Approval</span></h2>
                                                        <br>
                                                        <img src="../assets/images/process-1.png" class="col-lg-11 col-sm-12" alt="" srcset="">
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <div class="row">
                                                            <p class="text col-12">Answer simple questions about your property. Our AI-powered underwriting system swiftly evaluates your eligibility. In just a few minutes, receive your pre-approval decision and take the first step toward unlocking your home equity.</p>
                                                        </div>
                                                        <br>
                                                        <br>
                                                        <i class="pi pi-arrow-right" style="float: right; color: #FFFFFF; font-size: 22px"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row scroll-animate" data-animate="animate__fadeIn">
                                                <div class="col-lg-5 col-sm-12 d-flex align-items-center">
                                                  <div class="w-100">
                                                      <h2 class="title"><strong>02</strong> <span style="font-weight: 400;">Onboarding</span></h2>
                                                      <br>
                                                      <img src="../assets/images/process-2.2.png" class="col-lg-11 col-sm-12" alt="" srcset="">
                                                      <br>
                                                      <br>
                                                      <p class="text">Upload basic documents and chat with your dedicated mortgage advisor to discuss your options further if you’d like. Our team ensures you have all the information you need to make an informed decision about accessing your home equity. We're here to guide you every step of the way.</p>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row scroll-animate" data-animate="animate__fadeIn">
                                                <div class="col-lg-5 col-sm-12 d-flex align-items-center">
                                                    <div class="w-100">
                                                        <h2 class="title"><strong>03</strong> <span style="font-weight: 400;">Unlock</span></h2>
                                                        <br>
                                                        <img src="../assets/images/process-3.png" class="col-lg-11 col-sm-12" alt="" srcset="">
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <p class="text">Explore various options of offer - including how much you want to access. Select the one that suits your needs, and unlock your equity. Once official agreements are signed, you can expect the cash in your account in less than a week. Our streamlined process ensures quick access to the funds you need.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="d-none d-lg-block">
                <br>
                <br>
                <br>
                <br>
                <br>
            </div>
            <br>
            <br>
            <section class="how-it-works">
                <div class="row">
                    <div class="col-lg-5 col-sm-12 content scroll-animate" data-animate="animate__fadeInUp">
                        <div class="d-sm-none d-md-block">
                            <br>
                            <br>
                        </div>
                        <h2 :class="[!navbarIsVisible ? 'text-white' : null, 'title col-12']"><img src="../assets/images/how-it-works-1.png" height="50" alt="" srcset=""> How does it work?</h2>
                        <p class="text">Equihome empowers homeowners by simplifying home equity access. We provide upfront cash with no monthly payments throughout the term. At the end, fees are calculated based on a straightforward interest rate and property appreciation.
                            Homeowners have the flexibility to exit the contract at any time, offering ease and transparency.</p>
                        <br>
                        <br>
                        <div class="row footer">
                            <!-- <div class="col-lg-12 col-sm-12">
                                <div class="row"> -->
                                    <div class="col-5">
                                        <img src="../assets/images/how-it-works-2.png" alt="">
                                    </div>
                                    <div class="col-7">
                                        <span class="stats d-none d-lg-block"><img src="../assets/images/trending-up.png" alt=""><span class="desc">1500+ Waitlist Members</span></span>
                                        <span class="stats d-none d-md-block d-lg-none" style="padding-left: 15px;"><img src="../assets/images/trending-up.png" alt=""><span class="desc">1500+ Waitlist Members</span></span>
                                        <span class="stats d-sm-block d-md-none" style="padding-left: 15px;"><img src="../assets/images/trending-up.png" alt=""><span class="desc">1500+ Waitlist Members</span></span>
                                    </div>
                                <!-- </div>
                            </div> -->
                            <!-- <div class="col-6">
                                <span class="stats"><img src="../assets/images/trending-up.png" alt=""><span class="desc">1500+ Waitlist Members</span></span>
                            </div> -->
                        </div>
                        <br>
                        <div class="col-lg-8" style="padding-left: 0px;">
                            <router-link :to="{ name: WAITLIST_REGISTRATION }" class="col-12 btn btn-cta me-2">Join Waitlist</router-link>
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-sm-12">
                        <div class="d-sm-none d-md-block">
                            <br>
                            <br>
                        </div>
                        <div class="card card-shadow scroll-animate" data-animate="animate__fadeIn">
                            <div class="card-body">
                                <h4 class="title">Tap into your equity.</h4>
                                <p class="text">Get qualified today and have cash in your bank account in less than 3 weeks, ready to use for whatever you need.</p>
                            </div>
                        </div>
                        <div class="card card-colored scroll-animate" data-animate="animate__fadeIn">
                            <div class="card-body">
                                <h4 class="title">No payments for up to 15 years.</h4>
                                <p class="text">Use your equity without the burden of regular payments, giving you the financial flexibility to focus on what matters most.</p>
                            </div>
                        </div>
                        <div class="card card-shadow scroll-animate" data-animate="animate__fadeIn">
                            <div class="card-body">
                                <h4 class="title">Exit whenever.</h4>
                                <p class="text">Stay for the full term or exit early with no additional fees. You have the flexibility to exit by selling your property, refinancing, or accessing your equity again.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            <div class="d-none d-lg-block">
                <br>
                <br>
                <br>
                <br>
            </div>
            <br>

            <section class="platform">
                <div class="row">
                    <div class="col-lg-5 col-md-10 col-sm-12">
                        <br>
                        <br>
                        <br>
                        <h1 class="title text-center"><span>Your Home Equity</span> Platform</h1>
                        <br>
                        <img src="../assets/images/home-platform-section.svg" class="img-fluid col-12 d-lg-none sm-screen-img scroll-animate"  data-animate="animate__fadeInDown" alt="">
                        <br>
                        <br>
                        <p class="text">Welcome to your Equihome dashboard! Here, homeowners gain full visibility of their property's equity and financial potential. Track real-time home value updates, manage your equity share, and forecast future financial opportunities—all in one convenient place.</p>
                        <router-link :to="{ name: PLATFORM_ROUTE }" class="btn btn-see-more col-sm-12">See More</router-link>
                        <div class="d-none d-lg-block">
                            <br>
                        </div>
                        <br>
                        <br>
                        <h3 class="title-2">Also take a Peak into our Calculator</h3>
                        <p class="text">See how much you can access and what your Equihome Investment could look like over time.</p>
                        <br>
                        <div class="row">
                            <div class="col-lg-10 col-12 calculator-input">
                                <div class="search-card">
                                    <div class="search-container">
                                        <input v-model="amount" type="number" placeholder="Enter Estimated Home Value" autocomplete="off" id="home-value">
                                        <button @click="calculate()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <p class="text">Eg: $400,000</p>
                    </div>
                    <div class="col-lg-7 col-md-10 col-sm-12 img-col d-none d-lg-block">
                        <br>
                        <br>
                        <img src="../assets/images/home-platform-section.svg" class="img-fluid col-12 scroll-animate"  data-animate="animate__fadeInDown" alt="">
                    </div>
                </div>
            </section>

            <br>
            <br>
            <br>
            <br>
            <br>

            <section class="features" style="padding-left: 10%; padding-right: 10%;">
                <h4 class="title text-center col-12"><img src="../assets/images/desc-1.png" height="50" alt=""><span>We put homeowners first.</span></h4>
                <br>
                <br>
                <br>
                <div class="row">
                    <div class="col-lg-4 col-sm-12">
                        <div class="card card-colored scroll-animate" data-animate="animate__fadeInTopLeft">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <h4 class="title">Quick Approvals</h4>
                                <p class="text">Unlock your home’s value instantly with our proprietary technology that prioritizes you over statistics.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <div class="card card-noncolored scroll-animate" data-animate="animate__fadeInLeft">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <h4 class="title">Transparent Fees</h4>
                                <p class="text">We make it very clear what our fees are. Clearly understand and control your costs.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <div class="card card-colored scroll-animate" data-animate="animate__fadeInBottomLeft">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <h4 class="title">No Monthly Payments</h4>
                                <p class="text">Focus on what matters most. You don’t have the burden of monthly payments for the life of the loan.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-12">
                        <div class="card card-noncolored scroll-animate" data-animate="animate__fadeInTopRight">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <h4 class="title">Expert Guidance</h4>
                                <p class="text">Benefit from financial and legal advice tailored to you, your family, and your goals. See if Equihome is the right solution for you.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <div class="card card-colored scroll-animate" data-animate="animate__fadeInRight">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <h4 class="title">Personalized Dashboard</h4>
                                <p class="text">Manage your equity journey easily through our state of the art dashboard. Have full visibility of what’s due at all times.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <div class="card card-noncolored scroll-animate" data-animate="animate__fadeInBottomRight">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <h4 class="title">Exit Whenever</h4>
                                <p class="text">Exit at your convenience, be it on day 1 or day 1000, with zero early-payment fees involved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            <br>
            <br>
            <br>
            <br>

            <section class="comparison">
                <h4 class="title text-center col-12"><img src="../assets/images/desc-2.png" height="50" alt=""><span>How we compare.</span></h4>
                <br>
                <br>
                <div class="row">
                    <div class="col-4">
                        <div class="card">
                            <div class="card-body d-flex align-items-center">
                                <h6 class="text empty">-</h6>
                            </div>
                        </div>
                        <div class="card card-shaded">
                            <div class="card-body d-flex align-items-center">
                                <h6 class="text">No Monthly Payments</h6>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex align-items-center">
                                <h6 class="text">Stay in your home</h6>
                            </div>
                        </div>
                        <div class="card card-shaded">
                            <div class="card-body d-flex align-items-center">
                                <h6 class="text">Qualify at any age</h6>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex align-items-center">
                                <h6 class="text">No Prepayment Penalities</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 center-section">
                        <div class="card header">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <img src="../assets/images/logo-while.png" class="img-fluid col-lg-8 col-md-12 col-sm-12" alt="">
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <img src="../assets/images/check-circle-white.png" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <img src="../assets/images/check-circle-white.png" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <img src="../assets/images/check-circle-white.png" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <img src="../assets/images/check-circle-white.png" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card footer d-none d-lg-block">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <router-link :to="{ name: WAITLIST_REGISTRATION }" class="btn btn-apply-not">Join Waitlist</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <h6 class="text">Reverse Mortgage</h6>
                            </div>
                        </div>
                        <div class="card card-shaded">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <img src="../assets/images/check-circle.png" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <img src="../assets/images/check-circle.png" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card card-shaded">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <h6 class="text empty">-</h6>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <h6 class="text empty">-</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br>
            <br>
            <br>
            <br>
    
            <section class="offers" style="padding-left: 10%; padding-right: 10%;">
                <h4 class="title text-center"><img src="../assets/images/offers-1.png" height="50" alt=""><span>Make your home's wealth more valuable to you.</span></h4>
                <p class="text">Equihome empowers you to leverage your most valuable asset in pursuit of what truly matters for you. Explore how you can transform your home's equity into opportunities for personal and financial growth.</p>
                <br>
                <br>
                <div class="row" style="padding-left: 3%; padding-right: 3%;">
                    <div class="col-lg-4 col-sm-12 list-item">
                        <div class="row hoverable">
                            <div class="col-2">
                                <img src="../assets/images/features-1.png" alt="">
                            </div>
                            <div class="col-10">
                                <tooltip text="This is an example tooltip with an image and text">
                                    <h4 class="">Pay off your first mortgage</h4>
                                    <template v-slot:tooltip>
                                        <div class="row tool-tip-content">
                                            <div class="col-5">
                                                <img class="col-12" style="border-radius: 5px; text-align: justify" src="../assets/images/home-tool-tip-1.png" alt="">
                                            </div>
                                            <div class="col-7">
                                                <span>Eliminate the burden of monthly mortgage payments and enjoy the financial freedom of owning your home outright. Use your cash to settle your mortgage and secure your financial future.</span>
                                            </div>
                                        </div>
                                    </template>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 list-item">
                        <div class="row hoverable">
                            <div class="col-2">
                                <img src="../assets/images/features-2.png" alt="">
                            </div>
                            <div class="col-10">
                                <tooltip text="This is an example tooltip with an image and text">
                                    <h4 class="">Remodel</h4>
                                    <template v-slot:tooltip>
                                        <div class="row tool-tip-content">
                                            <div class="col-5">
                                                <img class="col-12" style="border-radius: 5px; text-align: justify" src="../assets/images/home-tool-tip-2.png" alt="">
                                            </div>
                                            <div class="col-7">
                                                <span>Transform your living space into your dream home. Whether it's a new kitchen, bathroom, or an entire home makeover, use your cash to invest in renovations that increase your home's value and comfort.</span>
                                            </div>
                                        </div>
                                    </template>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 list-item">
                        <div class="row hoverable">
                            <div class="col-2">
                                <img src="../assets/images/features-3.png" alt="">
                            </div>
                            <div class="col-10">
                                <tooltip text="This is an example tooltip with an image and text">
                                    <h4 class="">Pay off high interest debt</h4>
                                    <template v-slot:tooltip>
                                        <div class="row tool-tip-content">
                                            <div class="col-5">
                                                <img class="col-12" style="border-radius: 5px; text-align: justify" src="../assets/images/home-tool-tip-3.png" alt="">
                                            </div>
                                            <div class="col-7">
                                                <span>Reduce financial stress by paying off high-interest debts. Use your cash to consolidate loans and credit card balances, giving you peace of mind and a clear path to financial stability.</span>
                                            </div>
                                        </div>
                                    </template>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 list-item">
                        <div class="row hoverable">
                            <div class="col-2">
                                <img src="../assets/images/features-4.png" alt="">
                            </div>
                            <div class="col-10">
                                <tooltip text="This is an example tooltip with an image and text">
                                    <h4 class="">Invest your Money</h4>
                                    <template v-slot:tooltip>
                                        <div class="row tool-tip-content">
                                            <div class="col-5">
                                                <img class="col-12" style="border-radius: 5px; text-align: justify" src="../assets/images/home-tool-tip-4.png" alt="">
                                            </div>
                                            <div class="col-7">
                                                <span>Grow your wealth by investing in opportunities that align with your financial goals. Use your cash to diversify your investment portfolio, from stocks and bonds to real estate and beyond.</span>
                                            </div>
                                        </div>
                                    </template>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 list-item">
                        <div class="row hoverable">
                            <div class="col-2">
                                <img src="../assets/images/features-5.png" alt="">
                            </div>
                            <div class="col-10">
                                <tooltip text="This is an example tooltip with an image and text">
                                    <h4 class="">Take that Trip</h4>
                                    <template v-slot:tooltip>
                                        <div class="row tool-tip-content">
                                            <div class="col-5">
                                                <img class="col-12" style="border-radius: 5px; text-align: justify" src="../assets/images/home-tool-tip-5.png" alt="">
                                            </div>
                                            <div class="col-7">
                                                <span>Make unforgettable memories with the trip of a lifetime. Use your cash to explore new destinations, enjoy luxurious vacations, or visit family and friends around the world.</span>
                                            </div>
                                        </div>
                                    </template>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 list-item">
                        <div class="row hoverable">
                            <div class="col-2">
                                <img src="../assets/images/features-6.png" alt="">
                            </div>
                            <div class="col-10">
                                <tooltip text="This is an example tooltip with an image and text">
                                    <h4 class="">Buy another property</h4>
                                    <template v-slot:tooltip>
                                        <div class="row tool-tip-content">
                                            <div class="col-5">
                                                <img class="col-12" style="border-radius: 5px; text-align: justify" src="../assets/images/home-tool-tip-6.png" alt="">
                                            </div>
                                            <div class="col-7">
                                                <span>Expand your real estate portfolio or find a new home that better suits your needs. Use your cash to invest in additional properties, whether for personal use or as an income-generating rental.</span>
                                            </div>
                                        </div>
                                    </template>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="footer-card" style="padding-left: 10%; padding-right: 10%; position: relative; top: 20vh;">
            <div class="card">
                <div class="card-body">
                    <img src="../assets/images/footer-1.png" height="60" alt="">
                    <br>
                    <br>
                    <div style="padding-left: 1%; padding-right: 1%;">
                        <div class="row content">
                            <div class="col-lg-5 col-sm-12 scroll-animate" data-animate="animate__fadeIn">
                                <h3 class="title" style="overflow-wrap: normal">Free yourself from debt, start a business, or live your life.</h3>
                                <p class="caption">Enter your address and see instantly how much of your equity you can access</p>
                            </div>
                            
                            <div class="col-lg-6 col-sm-12 offset-lg-1 input-icon-container scroll-animate" data-animate="animate__fadeIn">
                                <div class="input-box col-12">
                                    <img src="../assets/images/mail.png" class="prefix" height="22" alt="" srcset="">
                                    <input type="tel" placeholder="Enter your email address here"/>
                                </div>                        
                                <br>
                                <router-link :to="{ name: WAITLIST_REGISTRATION }" class="col-12 btn btn-get-started me-2">Join Waitlist</router-link>
                            </div>
                        </div>
                    </div>
                    <img src="../assets/images/footer-1.png" style="float: right;" height="60" alt="">
                </div>
            </div>
        </div>
    
        <footer class="footer">
            <div class="copyright-area" style="padding-left: 10%; padding-right: 10%;">
                <div class="row">
                    <div class="col-lg-6 col-md-8 col-sm-8">
                        <p>© 2024 Equihome Partners. All rights reserved</p>
                    </div>
                    <div class="col-lg-2 offset-lg-4 col-md-3 offset-md-3 col-sm-3 offset-sm-3">
                        <router-link :to="{ name: PRIVACY_POLICY_ROUTE }">Privacy Policy</router-link>
                    </div>
                </div>
            </div>
    
        </footer>
    
    </div>
</template>
<script >
import { ref, onMounted, onUnmounted, onBeforeUnmount, watch } from 'vue';
import { useRouter } from 'vue-router';
import { WAITLIST_REGISTRATION, PARTNERS, FAQ_ROUTE, PLATFORM_ROUTE, LEARN_MORE_ROUTE, PRIVACY_POLICY_ROUTE } from '../utils/routes';
import navbar from '../components/navbar.vue'
import tooltip from '../components/tool-tip.vue';

export default {
    components: {
        navbar,
        tooltip
    },
  setup() {
    const router = useRouter();
    const isVisible = ref(false);
    const targetDiv = ref(null);
    const navbarIsVisible = ref(true);
    const scrollableIsVisible = ref(false);
    const swiperSection = ref(null);
    const swiperInstance = ref(null);
    const stickySections = ref([]);
    // const targetDiv = ref(null);

    function calculate() {
        router.push({ name: PLATFORM_ROUTE, query: { a: this.amount }, hash: '#calculator' });
    }

    const toggleVisibility = (state) => {
      isVisible.value = state;
      console.log('toggleVisibility', isVisible)
    };

    const nextSlide = () => {
      swiperInstance.value.slideNext();
    };

    const previousSlide = () => {
      swiperInstance.value.slidePrev();
    };

    const setSwiper = (swiper) => {
      swiperInstance.value = swiper;
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        // Toggle navbar visibility based on the intersection
        navbarIsVisible.value = !entry.isIntersecting;
      });
    }, {
      root: null, // null means it will observe relative to the viewport
      threshold: 0.275 // Triggers when 10% of sticky_parent is visible
    });

    const observer2 = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            scrollableIsVisible.value = entry.isIntersecting;
        });
      }, {
        root: null, // null means it will observe relative to the viewport
        threshold: 0.05 // Triggers when 10% of targetDiv is visible
    });

    // Reactive references for routes
    const waitlistRegistration = ref(WAITLIST_REGISTRATION);
    const partnerships = ref(PARTNERS);

    // const checkVisibility = () => {
    //   const element = targetDiv.value;
    //   const rect = element.getBoundingClientRect();
    //   const viewHeight = window.innerHeight || document.documentElement.clientHeight;

    //   // Check if the element is visible in the viewport
    //   scrollableIsVisible.value = rect.top >= 0 && rect.bottom <= viewHeight;
    // };

    const handleScroll = () => {
        for (let i = 0; i < stickySections.value.length; i++) {
          transform(stickySections.value[i]);
        }
      };
  
    const transform = (section) => {
        const offsetTop = section.parentElement.offsetTop;
        const scrollSection = section.querySelector('.scroll_section');

        let percentage = ((window.scrollY - offsetTop) / window.innerHeight) * 100;
        percentage = percentage < 0 ? 0 : percentage > 400 ? 400 : percentage;
        scrollSection.style.transform = `translate3d(${-percentage}vw, 0, 0)`;
    };
  

    // watch(navbarIsVisible, (newVal) => {
    //   if (newVal) {
    //     document.body.style.backgroundColor = 'white'; // Set to original color when navbar is visible
    //   } else {
    //     document.body.style.backgroundColor = '#052EBF'; // Change color when navbar is not visible
    //   }
    // });

    // Mounted lifecycle hook
    onMounted(() => {
        if (targetDiv.value) {
            observer.observe(targetDiv.value);
        }

        if (targetDiv.value) {
            observer2.observe(targetDiv.value);
        }

        stickySections.value = [...document.querySelectorAll('.sticky')];

      document.addEventListener("DOMContentLoaded", function() {
        // Callback function for Intersection Observer
        const callback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const animation = entry.target.getAttribute('data-animate');
                    entry.target.classList.add('animate__animated', animation);
                    observer.unobserve(entry.target); // Optional: Unobserve to stop further animations
                }
            });
        };

        // Create Intersection Observer
        const observer = new IntersectionObserver(callback);

        // Target elements to observe
        const targets = document.querySelectorAll('.scroll-animate');

        // Start observing each target
        targets.forEach(target => observer.observe(target));
      });
      if (swiperSection.value) {
        observer.observe(swiperSection.value);
      }

      window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('scroll', handleScroll);
    });
  

    onUnmounted(() => {
      if (swiperSection.value) {
        observer.disconnect(); // Clean up the observer when the component unmounts
      }

      if (targetDiv.value) {
        observer.disconnect();
      }

    //   if (targetDiv.value) {
    //     observer2.disconnect();
    //   }
    });

    return {
        WAITLIST_REGISTRATION,
        PARTNERS,
        FAQ_ROUTE,
        PLATFORM_ROUTE,
        LEARN_MORE_ROUTE,
        PRIVACY_POLICY_ROUTE,
        stickySections,
        targetDiv,
        amount: null,
        swiperInstance,
        targetDiv,
        isVisible,
        swiperSection,
        navbarIsVisible,
        scrollableIsVisible,
        calculate,
        toggleVisibility,
        setSwiper,
        previousSlide,
        nextSlide
    };
  }
}
</script>
<style scoped>
.content {
    width: 100%;
    height: 100%;
    
}

.sticky_parent {
    height: 300vh;
    margin-left: -5.5%;
    margin-right: -5.5%;
    padding-left: 0px;
    padding-right: 0px;
}

.sticky {
    overflow: hidden;
    position: sticky;
    top: 0;
    height: 90vh;
}

.scroll_section {
    position: absolute;
    top: 20px;
    height: 100%;
    width: 300vw;
    will-change: transform;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px
}

/*.scroll_section:first-child {
    padding-left: 12%!important;
}

.scroll_section:last-child {
    padding-right: 5%
}*/

  
/*.sticky_parent {
    position: relative;
    height: auto;
    margin-left: -5%;
    margin-right: -5%;
}*/

.sticky_parent {
    color: #FFFFFF;
    transition: background-color 1s ease;
}

.sticky_parent .row .col-lg-12 {
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.sticky_parent .swiper-button-next, 
.sticky_parent .swiper-button-prev {
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 10;
}

.sticky_parent .swiper-button-next, 
.sticky_parent .swiper-button-prev {
    position: absolute;
    top: 50%; 
    transform: translateY(-50%); /* Centers the button vertically */
    z-index: 10; /* Ensures the buttons are above other content */
    font-size: 30px;
}
  
.sticky_parent .swiper-button-next {
    right: 2%; /* Distance from the right edge */
}
  
.sticky_parent .swiper-button-prev {
    left: 2%; /* Distance from the left edge */
}

.sticky_parent .swiper-pagination {
    position: relative;
    top: 15px; 
}

.sticky_parent .card .card-body {
    padding-bottom: 10px;
}

.visible_sticky_section {
    background-color: #052EBF!important;
    transition: background-color 1s ease;
}

.invisible_sticky_section {
    background-color: #FFFFFF!important;
    transition: background-color 1s ease;
}

.input-box {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    padding-left: 0.5rem;
    overflow: hidden;
    border: 1px solid #404040;
    border-radius: 15px;
    height: 65px;
    font-size: 22px;
}
  
.input-box .prefix {
    font-weight: 300;
    font-size: 14px;
    padding-left: 10px;
    color: #999;
}
  
.input-box input {
    flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
}

.input-box input {
  flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
    padding-left: 0.8rem;
    font-size: 18px
}
  
.input-box:focus-within {
    border-color: #969696;
}

.main-card {
    padding-left: 0px;
    padding-right: 0px;
}

.before-nested,
.after-nested {
    padding: 20px;
    text-align: center;
}

.nested-scroll-container {
    position: relative;
	overflow-y: auto;
}   

.nested-content {
    padding: 20px;
    margin: 10px 0;
    opacity: 1;
    transition: opacity 0.5s ease;
}

.fade-out {
    opacity: 0.5 !important;
}

#indicator {
    position: absolute;
    top: 150px;
    left: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #indicator > div {
    background-color: green;
    width:10px;
    height:10px;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
  }
  
  #indicator > div.active {
    transform: scale(1.6);
  }

  ::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 6px;
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.platform .title {
    font-size: 40px;
}

.platform .sm-screen-img {
    padding-right: 0px;
    margin-right: -5%!important;
}

.platform .title span {
    font-weight: bold;
    background: -webkit-linear-gradient(left, #052EBF, #6BF893);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline;
}

.platform .title-2 {
    font-weight: 600;
}

.platform .text {
    font-size: 20px;
    color: #707070;
    text-align: justify
}

.platform .img-col {
    padding: 0px;
}

.platform .img-col img {
    padding: 0px;
}

.platform .btn-see-more {
    background-color: #052EBF;
    color: #FFFFFF;
    border-radius: 13px;
    font-size: 18px;
    padding: 15px 25px 15px 25px
}

.comparison .col-4 {
    padding: 0px;
}

.comparison .card {
    border-color: transparent;
}

.comparison .card .card-body {
    height: 100px;
}

.comparison .card .card-body .text {
    font-size: 18px;
    font-weight: 600;
}

.comparison .card-shaded {
    background-color: #F9F9F9;
}

.comparison .center-section {
    background-color: #052EBF;
    border-radius: 20px!important;
}

.comparison .center-section .card:first-child,
.comparison .center-section .card:last-child {
    border-radius: 20px!important;
}

.comparison .center-section .card {
    background-color: #052EBF;
}

.comparison .center-section .header {
    padding-top: 15px;
}

.comparison .center-section .footer {
    padding-bottom: 15px;
}

.comparison .card .card-body .empty {
    color: transparent!important;
}

.comparison .btn-apply-not {
    background-color: #FFFFFF;
    color: #000000;
    font-weight: 600;
    border-radius: 10px;
    font-size: 18px;
    padding: 15px 25px 15px 25px;
    width: 60%
}

.search-card {
    display: flex;
    border-radius: 10px;
    padding: 17px 10px 17px 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.search-container {
    display: flex;
    width: 100%;
    height: 50px;
}

.search-container input[type="number"] {
    min-width: 280px;
    border: 1px solid transparent;
    border-right: none;
    font-size: 18px;
    padding: 10px;
    height: 50px; 
    font-size: 18px;
    box-sizing: border-box;
    appearance: textfield;
}

.search-container input[type="number"]::-webkit-outer-spin-button,
.search-container input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.search-container input[type="number"]::placeholder {
    font-size: 18px;
}

.search-container input[type="number"]:focus {
    outline: none;
    border-color: #FFFFFF;
    background-color: #FFFFFF!important;
}

.search-container input[type="number"]:-webkit-autofill {
    outline: none;
    border-color: #FFFFFF;
    background-color: #FFFFFF!important;
}

.search-container button {
    width: 100px;
    background-color: #6BF893; /* Green background */
    border: 2px solid #6BF893; /* Same as background for a seamless look */
    color: white;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    color: #000000;
    float: right;
}

.search-container button {
    outline: none;
    border-color: transparent;
}

.search-container button:hover {
    background-color: #6BF893; /* Slightly darker green on hover */
}

@media (min-width: 1700px) {
    .content .hero-section .title {
        font-size: 80px!important;
    }
}

@media (min-width: 1000px) {
    .platform {
        width: auto;
        margin-right: -5%;
    }

    .platform .search-card {
        width: fit-content;
    }
}

@media (max-width: 1000px) {
    .center-section .header .card-body {
        padding: 0px;
    }

    .platform {
        width: auto;
        margin-right: -5%;
    }

    .platform .title,
    .platform .text,
    .platform .title-2,
    .platform .search-card {
        width: auto;
        margin-right: 5%!important;
    }

    .scroll_section .row {
        margin-left: 60px;
    }

    .search-container button {
        margin-right: 35px;
    }

}
</style>