export const LARGE_SCREEN_SIZE = 992
export const AXIOS_TIMEOUT = 30000

export const SERVER_ERR_MSG = 'Server Error!'

export const DEFAULT_ERROR_MSG = 'Error! Something went wrong.'
export const TOAST_GLOBAL_CONFIG = { position: 'top-right', dismissible: true }

export const LOCAL_STORAGE_KEY = 'local'

export const DATE_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
export const TIME_OPTIONS : Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
export const DATETIME_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export const UPLOAD_DOCUMENT_TAB_PERSONAL_INFORMATION = 'UPLOAD_DOCUMENT_TAB_PERSONAL_INFORMATION'
export const UPLOAD_DOCUMENT_TAB_DECLARATIONS = 'UPLOAD_DOCUMENT_TAB_DECLARATIONS'
export const UPLOAD_DOCUMENT_TAB_EMPLOYMENT = 'UPLOAD_DOCUMENT_TAB_EMPLOYMENT'
export const UPLOAD_DOCUMENT_TAB_PROPERTY = 'UPLOAD_DOCUMENT_TAB_PROPERTY'

export const PARTNERSHIP_CONTACT_FORM_TYPE_OF_INVESTOR = [
    'Private Equity',
    'Venture Capital',
    'Family Office',
    'Individual',
    'Other'
]

export const PARTNERSHIP_CONTACT_FORM_INVESTMENT_SIZE = [
    '$0-250k',
    '$250k-$2m',
    '$2m-$10m',
    '$10m+'
]