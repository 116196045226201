import { AxiosResponse } from 'axios'
import { SET_CONTACT_FORM, SET_ERRORMSG } from '../../mutation_types'
import {
    CONTACT_FORM_REGISTRATION,
    CLEAR_CONTACT_FORM_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state'
import ContactForm from '@/services/partnership/contact_form'
import { Module } from 'vuex'
import { ContactFormBody } from '@/dtos/partership/contact_form'

const contactFormRegistration: Module<any, {}> = {
    state: {
        contactFormRegistration: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        contactFormRegistration: (state: { contactFormRegistration: any }) => state.contactFormRegistration
    },
    mutations: {
        [SET_CONTACT_FORM]: (state: { contactFormRegistration: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.contactFormRegistration.data = payload.data : null, state.contactFormRegistration.isLoading = payload.isLoading },
    },
    actions: {
        [CONTACT_FORM_REGISTRATION]: async({ commit }: any, payload: any) => {
            commit(SET_CONTACT_FORM, { data: null, isLoading: true })

            let result: AxiosResponse = await ContactForm.post(payload as ContactFormBody)

            if(result) {
                if (result.data.status) {
                    commit(SET_CONTACT_FORM, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CONTACT_FORM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CONTACT_FORM, { isLoading: false })
            }
            return null
        },
        [CLEAR_CONTACT_FORM_ISLOADING]: ({ commit }) => {
            commit(SET_CONTACT_FORM, { isLoading: false })
        }
    }
}

export default contactFormRegistration