import { AxiosResponse } from 'axios'
import { SET_WAITLIST_REGISTRATION, SET_ERRORMSG } from '../mutation_types'
import {
    WAITLIST_REGISTRATION,
    CLEAR_WAITLIST_ISLOADING
} from '../action_types'
import { StateObject } from '@/dtos/state'
import WaitlistRegistration from '@/services/waitlist_registration'
import { Module } from 'vuex'
import { WaitlistRegistrationBody } from '@/dtos/waitlist_registration'

const waitlistRegistration: Module<any, {}> = {
    state: {
        waitlistRegistration: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        waitlistRegistration: (state: { waitlistRegistration: any }) => state.waitlistRegistration
    },
    mutations: {
        [SET_WAITLIST_REGISTRATION]: (state: { waitlistRegistration: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.waitlistRegistration.data = payload.data : null, state.waitlistRegistration.isLoading = payload.isLoading },
    },
    actions: {
        [WAITLIST_REGISTRATION]: async({ commit }: any, payload: any) => {
            commit(SET_WAITLIST_REGISTRATION, { data: null, isLoading: true })

            let result: AxiosResponse = await WaitlistRegistration.post(payload as WaitlistRegistrationBody)

            if(result) {
                if (result.data.status) {
                    commit(SET_WAITLIST_REGISTRATION, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_WAITLIST_REGISTRATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_WAITLIST_REGISTRATION, { isLoading: false })
            }
            return null
        },
        [CLEAR_WAITLIST_ISLOADING]: ({ commit }) => {
            commit(SET_WAITLIST_REGISTRATION, { isLoading: false })
        }
    }
}

export default waitlistRegistration