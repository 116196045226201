// waitlist
export const WAITLIST_REGISTRATION = 'registerWaitlist'
export const CLEAR_WAITLIST_ISLOADING = 'clearWaitlistIsLoading'

// account
export const SIGNIN = 'signin'
export const GOOGLE_SIGNIN = 'googleSignin'
export const SIGNUP = 'signup'
export const VERIFY_EMAIL = 'verifyEmail'
export const CLEAR_ACCOUNT_ISLOADING = 'clearAccountIsLoading'

// partnership
export const CONTACT_US_FORM_REGISTRATION = 'registerContactUsForm'
export const CLEAR_CONTACT_US_FORM_ISLOADING = 'clearContactUsFormIsLoading'

// pre_approval
export const PRE_APPROVAL_REGISTRATION = 'registerPreApproval'
export const CLEAR_PRE_APPROVAL_ISLOADING = 'clearPreApprovalIsLoading'

// personal_information
export const PERSONAL_INFORMATION_REGISTRATION = 'registerPersonalInformation'
export const CLEAR_PERSONAL_INFORMATION_ISLOADING = 'clearPersonalInformationIsLoading'

// declaration
export const DECLARATION_REGISTRATION = 'registerDeclaration'
export const CLEAR_DECLARATION_ISLOADING = 'clearDeclarationIsLoading'

// employment
export const EMPLOYMENT_REGISTRATION = 'registerEmployment'
export const CLEAR_EMPLOYMENT_ISLOADING = 'clearEmploymentIsLoading'

// property
export const PROPERTY_REGISTRATION = 'registerProperty'
export const CLEAR_PROPERTY_ISLOADING = 'clearPropertyIsLoading'

// partnership
export const CONTACT_FORM_REGISTRATION = 'registerContactForm'
export const CLEAR_CONTACT_FORM_ISLOADING = 'clearContactFormIsLoading'

// places
export const GET_PLACES_SUGGESTION = 'getPlacesSuggestion'
export const GET_PLACES_DETAIL = 'getPlacesDetail'
export const CLEAR_PLACES_ISLOADING = 'clearPlaces'

// error 
export const CLEAR_ERRORMSG = 'clearErrorMessage'
export const UPDATE_ERRORMSG = 'updateErrorMessage'