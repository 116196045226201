// waitlist
export const WAITLIST_REGISTRATION_URL = '/waitlist/register'

// account
export const SIGNUP_URL = '/auth/signup'
export const SIGNIN_URL = '/auth/login'
export const GOOGLE_SIGNIN_URL = '/auth/google'
export const EMAIL_VERIFICATION_URL = '/auth/verifyEmail'

// contact_us
export const CONTACT_US_FORM_URL = '/contactUs/form'

// pre_approval
export const PRE_APPROVAL_URL = '/preApproval'

// personal_information
export const PERSONAL_INFORMATION_URL = '/personalInformation'

// declaration
export const DECLARATION_URL = '/declaration'

// employment
export const EMPLOYMENT_URL = '/employment'

// property
export const PROPERTY_URL = '/property'

// partnership
export const PARTNERSHIP_CONTACT_FORM_URL = '/partnership/contactForm'

// places
export const PLACES_SUGGESTION_URL = '/places/suggestion'
export const PLACES_DETAIL_URL = '/places/details'