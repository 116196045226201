export const HOME_ROUTE = 'HOME_ROUTE'
export const PARTNERS = 'PARTNERS'
export const FAQ_ROUTE = 'FAQ_ROUTE'
export const PLATFORM_ROUTE = 'PLATFORM_ROUTE'
export const LEARN_MORE_ROUTE = 'LEARN_MORE_ROUTE'
export const WHY_US_ROUTE = 'WHY_US_ROUTE'
export const CONTACT_US_ROUTE = 'CONTACT_US_ROUTE'
export const PRIVACY_POLICY_ROUTE = 'PRIVACY_POLICY_ROUTE'
export const WAITLIST_REGISTRATION = 'WAITLIST_REGISTRATION'
export const WAITLIST_REGISTRATION_THANKYOU = 'WAITLIST_REGISTRATION_THANKYOU'
export const SIGNUP = 'SIGNUP'
export const SIGNIN = 'SIGNIN'
export const WELCOME = 'WELCOME'
export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const PRE_APPROVAL_QUESTIONS = 'PRE_APPROVAL_QUESTIONS'
export const PRE_APPROVAL_GREETINGS = 'PRE_APPROVAL_GREETINGS'
export const CONDITIONAL_APPROVAL = 'CONDITIONAL_APPROVAL'
export const DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD'
export const FINAL_OFFER = 'FINAL_OFFER'