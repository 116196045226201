import { AxiosResponse } from 'axios'
import { SET_PROPERTY_REGISTRATION, SET_ERRORMSG } from '../../mutation_types'
import {
    PROPERTY_REGISTRATION,
    CLEAR_PROPERTY_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state'
import Property from '@/services/document_upload/property'
import { Module } from 'vuex'
import { PropertyBody } from '@/dtos/document_upload/property'
// import { PropertyBody } from '@/dtos/pre_approval'

const property: Module<any, {}> = {
    state: {
        property: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        property: (state: { property: any }) => state.property
    },
    mutations: {
        [SET_PROPERTY_REGISTRATION]: (state: { property: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.property.data = payload.data : null, state.property.isLoading = payload.isLoading },
    },
    actions: {
        [PROPERTY_REGISTRATION]: async({ commit }: any, payload: PropertyBody) => {
            commit(SET_PROPERTY_REGISTRATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Property.post(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_PROPERTY_REGISTRATION, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_PROPERTY_REGISTRATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_PROPERTY_REGISTRATION, { isLoading: false })
            }
            return null
        },
        [CLEAR_PROPERTY_ISLOADING]: ({ commit }) => {
            commit(SET_PROPERTY_REGISTRATION, { isLoading: false })
        }
    }
}

export default property