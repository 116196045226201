import { AxiosResponse } from 'axios'
import { SET_PLACES_SUGGESTIONS, SET_PLACE_DETAIL, SET_ERRORMSG } from '../../mutation_types'
import {
    GET_PLACES_SUGGESTION,
    GET_PLACES_DETAIL,
    CLEAR_PLACES_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state'
import Places from '@/services/google_cloud/places'
import { Module } from 'vuex'
import { PlacesSuggestionQuery, PlaceDetail } from '@/dtos/google_cloud/places'

const places: Module<any, {}> = {
    state: {
        placeSuggestions: {
            data: [],
            isLoading: false
        },
        placeDetail: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        placeSuggestions: (state: { placeSuggestions: any }) => state.placeSuggestions,
        placeDetail: (state: { placeDetail: any }) => state.placeDetail
    },
    mutations: {
        [SET_PLACES_SUGGESTIONS]: (state: { placeSuggestions: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.placeSuggestions.data = payload.data : null, state.placeSuggestions.isLoading = payload.isLoading },
        [SET_PLACE_DETAIL]: (state: { placeDetail: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.placeDetail.data = payload.data : null, state.placeDetail.isLoading = payload.isLoading },
    },
    actions: {
        [GET_PLACES_SUGGESTION]: async({ commit }: any, payload: any) => {
            commit(SET_PLACES_SUGGESTIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Places.getSuggestions(payload as PlacesSuggestionQuery)

            if(result) {
                if (result.data.status) {
                    commit(SET_PLACES_SUGGESTIONS, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_PLACES_SUGGESTIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_PLACES_SUGGESTIONS, { isLoading: false })
            }
            return null
        },
        [GET_PLACES_DETAIL]: async({ commit }: any, payload: any) => {
            commit(SET_PLACE_DETAIL, { data: null, isLoading: true })

            let result: AxiosResponse = await Places.getDetail(payload as PlaceDetail)

            if(result) {
                if (result.data.status) {
                    commit(SET_PLACE_DETAIL, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_PLACE_DETAIL, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_PLACE_DETAIL, { isLoading: false })
            }
            return null
        },
        [CLEAR_PLACES_ISLOADING]: ({ commit }) => {
            commit(SET_PLACES_SUGGESTIONS, { isLoading: false })
            commit(SET_PLACE_DETAIL, { isLoading: false })
        }
    }
}

export default places