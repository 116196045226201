import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filters from './filters/index'
import titleMixin from './mixin/titleMixin'
import GoogleAnalytics from './plugins/google-analytics';
import ToastPlugin from 'vue-toast-notification';
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/vue-tel-input.css';
import 'primeicons/primeicons.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

import { createHead } from '@vueuse/head'

import GoogleSignInPlugin from "vue3-google-signin"

// import '~swiper/swiper-bundle.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';

const app = createApp(App)
app.mixin(titleMixin)

const head = createHead()

const gauthOption = {
    clientId: '491545240442-grqorfrb87lvlt4l8fsoo3tbfqcerngq.apps.googleusercontent.com'
}

app.use(head).use(GoogleAnalytics).use(VueTelInput).use(Autocomplete).use(GoogleSignInPlugin, gauthOption).use(ToastPlugin).use(VueAwesomeSwiper).use(store).use(router).mount("#app");

app.config.globalProperties.$filters = filters
app.config.globalProperties.$loadAnalytics();

