<template>
    <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
      <slot></slot>
      <div v-if="visible" class="tooltip-content">
        <slot name="tooltip"></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageSrc: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        visible: false,
      };
    },
    computed: {
      tooltipStyle() {
        return {
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#333',
          color: '#fff',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
        };
      },
    },
    methods: {
      showTooltip() {
        this.visible = true;
      },
      hideTooltip() {
        this.visible = false;
      },
    },
  };
  </script>
  
<style scoped>
.tooltip-container {
    position: relative;
    display: inline-block;
}
  
.tooltip-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FFFFFF;
    color: #000000;
    padding: 10px;
    border-radius: 10px;
    z-index: 1000;
    width: 700px;
    display: flex;
    align-items: center;
    border: 1px solid rgb(223, 220, 220);
}
  
.tooltip-image {
    margin-right: 10px;
    max-width: 50px;
    max-height: 50px;
}

@media (max-width: 1000px) {
    .tooltip-content {
      left: 90px;
      width: 400px;
    }
}
  </style>
  