import baseAPI from '@/api/base';
import { PERSONAL_INFORMATION_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { PersonalInformationBody } from '@/dtos/document_upload/personal_information';

class PersonalInformation {
    static post = async(formData: FormData) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(PERSONAL_INFORMATION_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default PersonalInformation