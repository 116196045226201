import baseAPI from '@/api/base';
import { CONTACT_US_FORM_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { ContactUsFormBody } from '@/dtos/contact_us/form';

class ContactUsForm {
    static post = async(contactUsFormBody: ContactUsFormBody) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(CONTACT_US_FORM_URL, {
                    ...contactUsFormBody
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default ContactUsForm