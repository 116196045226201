import { createStore } from "vuex";
import createVuexPersistedState from 'vuex-persistedstate';
import error from "./modules/error";
import waitlistRegistration from "./modules/waitlist";
import account from "./modules/auth";
import { LOCAL_STORAGE_KEY } from "@/utils/const";
import preApprovalRegistration from "./modules/pre_approval";
import personalInformation from "./modules/document_upload/personal_information";
import declaration from "./modules/document_upload/declaration";
import employment from "./modules/document_upload/employment";
import property from "./modules/document_upload/property";
import contactFormRegistration from "./modules/partnership/contact_form";
import places from "./modules/google_cloud/places";
import contactUsFormRegistration from "./modules/contact_us/form";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    contactFormRegistration,
    contactUsFormRegistration,
    declaration,
    employment,
    error,
    personalInformation,
    places,
    preApprovalRegistration,
    property,
    waitlistRegistration
  },
  plugins: [
    createVuexPersistedState({
        key: LOCAL_STORAGE_KEY,
        storage: window.localStorage
    })
  ]
});
