import { createRouter, RouteRecordRaw, createWebHistory, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';
import HomeView from "../views/Home.vue";
import { CONDITIONAL_APPROVAL, CONTACT_US_ROUTE, DOCUMENT_UPLOAD, FAQ_ROUTE, FINAL_OFFER, HOME_ROUTE, LEARN_MORE_ROUTE, PARTNERS, PLATFORM_ROUTE, PRE_APPROVAL_GREETINGS, PRE_APPROVAL_QUESTIONS, PRIVACY_POLICY_ROUTE, SIGNIN, SIGNUP, VERIFY_EMAIL, WAITLIST_REGISTRATION, WAITLIST_REGISTRATION_THANKYOU, WELCOME, WHY_US_ROUTE } from "@/utils/routes";
import { resetLoaders } from "@/utils/loaders";
import { ifNotAuthDeny } from "./guards/not_auth";
import { ifAuthDeny } from "./guards/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: HOME_ROUTE,
    component: HomeView,
  },
  {
    path: "/register-waitlist",
    name: WAITLIST_REGISTRATION,
    component: () =>
      import("../views/Waitlist_Register.vue"),
  },
  {
    path: "/partners",
    name: PARTNERS,
    component: () =>
      import("../views/Partnerships.vue"),
  },
  {
    path: "/help-center",
    name: FAQ_ROUTE,
    component: () =>
      import("../views/FAQ.vue"),
  },
  {
    path: "/platform",
    name: PLATFORM_ROUTE,
    component: () =>
      import("../views/Platform.vue"),
  },
  {
    path: "/learn-more",
    name: LEARN_MORE_ROUTE,
    component: () =>
      import("../views/Learn_More.vue"),
  },
  {
    path: "/why-us",
    name: WHY_US_ROUTE,
    component: () =>
      import("../views/Why_Us.vue"),
  },
  {
    path: "/contact-us",
    name: CONTACT_US_ROUTE,
    component: () =>
      import("../views/Contact_Us.vue"),
  },
  {
    path: "/privacy-policy",
    name: PRIVACY_POLICY_ROUTE,
    component: () =>
      import("../views/Privacy_Policy.vue"),
  },
  {
    path: "/thankyou",
    name: WAITLIST_REGISTRATION_THANKYOU,
    component: () =>
      import("../views/Thank_You.vue"),
  },
  {
    path: "/signup",
    name: SIGNUP,
    beforeEnter: ifAuthDeny,
    component: () =>
      import("../views/auth/Signup.vue"),
  },
  {
    path: "/signin",
    name: SIGNIN,
    beforeEnter: ifAuthDeny,
    component: () =>
      import("../views/auth/Signin.vue"),
  },
  {
    path: "/welcome",
    name: WELCOME,
    component: () =>
      import("../views/auth/Welcome.vue"),
  },
  {
    path: "/verify/:verificationToken",
    name: VERIFY_EMAIL,
    component: () =>
      import("../views/auth/Verify_Email.vue"),
  },
  {
    path: "/pre-approval-questions",
    name: PRE_APPROVAL_QUESTIONS,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Pre_Approval_Questions.vue"),
  },
  {
    path: "/pre-approval-greetings",
    name: PRE_APPROVAL_GREETINGS,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Pre_Approval_Greetings.vue"),
  },
  {
    path: "/conditional-approval",
    name: CONDITIONAL_APPROVAL,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Conditional_Approval.vue"),
  },
  {
    path: "/document-upload",
    name: DOCUMENT_UPLOAD,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Document_Upload.vue"),
  },
  {
    path: "/final-offer",
    name: FINAL_OFFER,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Final_Offer.vue"),
  } 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return the saved position or scroll to the top
    if (to.hash) {
      if(to.hash != '#calculator') {
        return new Promise(resolve => {
          setTimeout(() => {
            const element = document.getElementById(to.hash.replace('#', ''));
            if (element) {
              resolve({
                el: element,
                behavior: 'smooth' // ensure smooth scrolling where supported
              });
            } else {
              resolve({ left: 0, top: 0 });
            }
          }, 500); // delay to account for dynamic content loading
        });
      }
    // } else if (savedPosition) {
    //   return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  resetLoaders()

  next();
});


export default router;
