import { AxiosResponse } from 'axios'
import { SET_EMPLOYMENT_REGISTRATION, SET_ERRORMSG } from '../../mutation_types'
import {
    EMPLOYMENT_REGISTRATION,
    CLEAR_EMPLOYMENT_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state'
import Employment from '@/services/document_upload/employment'
import { Module } from 'vuex'
import { EmploymentBody } from '@/dtos/document_upload/employment'
// import { EmploymentBody } from '@/dtos/pre_approval'

const employment: Module<any, {}> = {
    state: {
        employment: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        employment: (state: { employment: any }) => state.employment
    },
    mutations: {
        [SET_EMPLOYMENT_REGISTRATION]: (state: { employment: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.employment.data = payload.data : null, state.employment.isLoading = payload.isLoading },
    },
    actions: {
        [EMPLOYMENT_REGISTRATION]: async({ commit }: any, payload: EmploymentBody) => {
            commit(SET_EMPLOYMENT_REGISTRATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Employment.post(payload.data)

            if(result) {
                if (result.data.status) {
                    commit(SET_EMPLOYMENT_REGISTRATION, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_EMPLOYMENT_REGISTRATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_EMPLOYMENT_REGISTRATION, { isLoading: false })
            }
            return null
        },
        [CLEAR_EMPLOYMENT_ISLOADING]: ({ commit }) => {
            commit(SET_EMPLOYMENT_REGISTRATION, { isLoading: false })
        }
    }
}

export default employment