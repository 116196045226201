import { AxiosResponse } from 'axios'
import { SET_DECLARATION_REGISTRATION, SET_ERRORMSG } from '../../mutation_types'
import {
    DECLARATION_REGISTRATION,
    CLEAR_DECLARATION_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state'
import Declaration from '@/services/document_upload/declaration'
import { Module } from 'vuex'
import { DeclarationBody } from '@/dtos/document_upload/declaration'
// import { DeclarationBody } from '@/dtos/pre_approval'

const declaration: Module<any, {}> = {
    state: {
        declaration: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        declaration: (state: { declaration: any }) => state.declaration
    },
    mutations: {
        [SET_DECLARATION_REGISTRATION]: (state: { declaration: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.declaration.data = payload.data : null, state.declaration.isLoading = payload.isLoading },
    },
    actions: {
        [DECLARATION_REGISTRATION]: async({ commit }: any, payload: DeclarationBody) => {
            commit(SET_DECLARATION_REGISTRATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Declaration.post(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_DECLARATION_REGISTRATION, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_DECLARATION_REGISTRATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_DECLARATION_REGISTRATION, { isLoading: false })
            }
            return null
        },
        [CLEAR_DECLARATION_ISLOADING]: ({ commit }) => {
            commit(SET_DECLARATION_REGISTRATION, { isLoading: false })
        }
    }
}

export default declaration