import { App } from 'vue';

export default {
  install(app: App): void {
    app.config.globalProperties.$loadAnalytics = function() {
      const script = document.createElement('script');
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-6D7J1WTHNN";
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        
        // Proper definition of gtag with parameters
        function gtag(...args: any[]): void {
          window.dataLayer.push(args);
        }
        
        // Correct usage of gtag with parameters
        gtag('js', new Date());
        gtag('config', 'G-6D7J1WTHNN');
      };
    };
  }
};
