import baseAPI from '@/api/base';
import { GoogleSigninCredentals, LoginCredentals, RegistrationCredentals } from '../dtos/auth.dto';
import { EMAIL_VERIFICATION_URL, GOOGLE_SIGNIN_URL, SIGNIN_URL, SIGNUP_URL } from '@/utils/urls';
import asyncHandler from '@/utils/async_handler';
import { AxiosResponse } from 'axios';

class Auth {
    static login = async(loginCredentals: LoginCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(SIGNIN_URL, {
                    ...loginCredentals,

                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static googleSignIn = async(googleSigninCredentals: GoogleSigninCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(GOOGLE_SIGNIN_URL, {
                    ...googleSigninCredentals

                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static signup = async(registrationCredentals: RegistrationCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(SIGNUP_URL, {
                    ...registrationCredentals
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static verifyEmail = async(verificationToken: String) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${EMAIL_VERIFICATION_URL}/${verificationToken}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Auth