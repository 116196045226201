import { SET_ERRORMSG } from '../mutation_types'
import { CLEAR_ERRORMSG, UPDATE_ERRORMSG } from '../action_types'

const error = {
    state: {
        errorMsg: null
    },
    getters: {
        errorMsg: (state: { errorMsg: String }) => state.errorMsg
    },
    mutations: {
        [SET_ERRORMSG]: (state: { errorMsg: String }, payload: any) => { state.errorMsg = payload }
    },
    actions: {
        [CLEAR_ERRORMSG]: ({ commit }: any) => {
            commit(SET_ERRORMSG, null)
        },
        [UPDATE_ERRORMSG]: ({ commit } : any, payload: any) => {
            commit(SET_ERRORMSG, payload.errorMsg)
        }
    }
}

export default error