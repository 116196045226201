<template>
    <nav class="navbar sticky-top scroll-animate" data-animate="animate__fadeIn">
        <a class="navbar-brand" href="https://www.equihome.com.au"><img height="40" src="../assets/images/logo.png" alt=""></a>
        <button class="navbar-toggler" type="button" @click="toggleMenu" v-show="!isDesktop">
            <i class="pi pi-align-justify"></i>
        </button>
        <div class="form-inline my-2 my-lg-0 d-none d-lg-block">
            <router-link :to="{ name: LEARN_MORE_ROUTE }" :class="[route == LEARN_MORE_ROUTE ? 'nav-btn-selected' : null, 'btn nav-btn my-2 my-sm-0']">Learn More</router-link>
            <router-link :to="{ name: PLATFORM_ROUTE }" :class="[route == PLATFORM_ROUTE ? 'nav-btn-selected' : null, 'btn nav-btn my-2 my-sm-0']">Platform</router-link>
            <router-link :to="{ name: WHY_US_ROUTE }" :class="[route == WHY_US_ROUTE ? 'nav-btn-selected' : null, 'btn nav-btn my-2 my-sm-0']">Why Us?</router-link>
            <router-link :to="{ name: PARTNERS }" :class="[route == PARTNERS ? 'nav-btn-selected' : null, 'btn nav-btn my-2 my-sm-0']">Partners</router-link>
            <router-link :to="{ name: FAQ_ROUTE }" :class="[route == FAQ_ROUTE ? 'nav-btn-selected' : null, 'btn nav-btn my-2 my-sm-0']">Help Center</router-link>
            <router-link :to="{ name: CONTACT_US_ROUTE }" :class="[route == CONTACT_US_ROUTE ? 'nav-btn-selected' : null, 'btn nav-btn my-2 my-sm-0']">Contact Us</router-link>
            <router-link :to="{ name: WAITLIST_REGISTRATION }" class="btn cta-btn my-2 my-sm-0">Join Waitlist</router-link>
        </div>
        
        <div v-bind:class="{ 'show-menu': isMenuOpen }" class="mobile-menu">
            <div class="row">
                <div class="col-10">
                    <a class="navbar-brand" href="https://www.equihome.com.au"><img height="40" src="../assets/images/logo.png" alt=""></a>
                </div>
                <div class="col-2">
                    <button class="close-menu" @click="toggleMenu">&times;</button>
                </div>
            </div>
            <hr>

            <router-link :to="{ name: LEARN_MORE_ROUTE }" :class="[route == LEARN_MORE_ROUTE ? 'nav-btn-selected' : null, 'btn col-12 nav-btn my-2 my-sm-0']"><span class="float-left">Learn More</span></router-link>
            <router-link :to="{ name: PLATFORM_ROUTE }" :class="[route == PLATFORM_ROUTE ? 'nav-btn-selected' : null, 'btn col-12 nav-btn my-2 my-sm-0']"><span class="float-left">Platform</span></router-link>
            <router-link :to="{ name: WHY_US_ROUTE }" :class="[route == WHY_US_ROUTE ? 'nav-btn-selected' : null, 'btn col-12 nav-btn my-2 my-sm-0']"><span class="float-left">Why Us?</span></router-link>
            <router-link :to="{ name: PARTNERS }" :class="[route == PARTNERS ? 'nav-btn-selected' : null, 'btn col-12 nav-btn my-2 my-sm-0']"><span class="float-left">Partners</span></router-link>
            <router-link :to="{ name: FAQ_ROUTE }" :class="[route == FAQ_ROUTE ? 'nav-btn-selected' : null, 'btn col-12 nav-btn my-2 my-sm-0']"><span class="float-left">Help Center</span></router-link>
            <router-link :to="{ name: CONTACT_US_ROUTE }" :class="[route == CONTACT_US_ROUTE ? 'nav-btn-selected' : null, 'btn col-12 nav-btn my-2 my-sm-0']"><span class="float-left">Contact Us</span></router-link>
            <router-link :to="{ name: WAITLIST_REGISTRATION }" class="btn col-12 cta-btn my-2 my-sm-0">Join Waitlist</router-link>
            <!-- Add other links similarly -->
          </div>
    </nav>
</template>
<script>
import { WAITLIST_REGISTRATION, PARTNERS, WHY_US_ROUTE, FAQ_ROUTE, PLATFORM_ROUTE, LEARN_MORE_ROUTE, CONTACT_US_ROUTE } from '../utils/routes';

export default {
    data() {
        return {
            PARTNERS,
            WHY_US_ROUTE,
            WAITLIST_REGISTRATION,
            FAQ_ROUTE,
            PLATFORM_ROUTE,
            LEARN_MORE_ROUTE,
            CONTACT_US_ROUTE,
            isMenuOpen: false,
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        isDesktop() {
            return this.windowWidth >= 992;
        },
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
    
</script>
<style scoped>
.mobile-menu {
    position: fixed;
    width: 250px;
    height: 100%;
    width: 100%;
    background-color: #fff;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    padding: 20px;
    box-sizing: border-box;
}

.mobile-menu .nav-btn {
    float: left;
}

.show-menu {
    transform: translateX(0);
}

.close-menu {
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
    border: none;
    background: none;
    cursor: pointer;
}
</style>